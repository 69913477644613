<template>
  <div class="change-mobile">
    <ui-input
      label="Email"
      name="userName"
      placeholder="Please enter your email"
      :validate="true"
      labelWidth="127px"
      v-model="userName"
    ></ui-input>
    <ui-code
      label="Code"
      :validate="true"
      :email="userName"
      name="VerificationCode"
      placeholder="E-mail verification code"
      v-model="code"
      labelWidth="127px"
    ></ui-code>

    <ui-input
      label="New Password"
      name="NewPassword"
      :validate="true"
      placeholder="New Password"
      v-model="pwd"
      type='password'
      labelWidth="127px"
    ></ui-input>
    <ui-input
      label="Confirm Password"
      name="ConfirmPassword"
      :validate="true"
      placeholder="Confirm Password"
      v-model="repeatPwd"
      labelWidth="127px"
      type="password"
    ></ui-input>
    <ui-button class="submit" @handleclick="onSubmit" type="submit">submit</ui-button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, inject } from "vue";
import UiInput from "@/components/UI/Input";
import UiCode from "@/components/UI/Code";
import UiButton from "@/components/UI/Button";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import qs from "qs";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "Forget",
  components: {
    UiInput,
    UiCode,
    UiButton,
  },
  setup() {
    const userName = ref("");
    const code = ref("");
    const pwd = ref("");
    const repeatPwd = ref("");
    const router = useRouter();

    function checkCode(code: string) {
      const reg = /^[0-9]{6}$/;
      console.log(code);
      if (!code || !reg.test(code)) {
        Toast({
          type: "warning",
          title: "Please enter the correct verification code.",
        });
        return false;
      }
      return true;
    }
    function checkPwd(pwd: string, rePwd: string) {
      if (pwd != rePwd) {
        Toast({
          type: "warning",
          // title: "两次密码不一致，请检查",
          title: "The two passwords you entered are inconsistent, please re-enter.",
        });
        return false;
      }
      return true;
    }
    function onSubmit() {
      if (!checkCode(code.value) || !checkPwd(pwd.value, repeatPwd.value)) {
        return false;
      }
      const data = qs.stringify({
        userName: userName.value,
        code: code.value,
        newpass: repeatPwd.value,
        prepass: pwd.value,
      });
      axios
        .post("/M/Server/DoForgetPassWord", data)
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
              onClose: () => {
                localStorage.removeItem("token");
                router.push({
                  name: "Mine",
                });
              },
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: err.message || err,
          });
        });
    }

    return {
      userName,
      code,
      pwd,
      repeatPwd,
      onSubmit
    };
  },
});
</script>

<style lang="scss" scoped>
.change-mobile {
  padding: 0 10px;
}
.submit {
  margin-top: 40px;
}
</style>
